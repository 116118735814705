import Link from 'next/link'
import styles from '../app/styles/TopBar.module.sass'

export function TopBar() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<article>
					Nově vyrábíme dřevěné lightboxy s vyměnitelnou grafikou!
					<Link
						href="/product/dreveny-lightbox-s-vymenitelnym-celnim-plexisklem"
						style={{ textDecoration: 'underline' }}>
						Koukněte se!
					</Link>{' '}
				</article>
			</div>
		</div>
	)
}
